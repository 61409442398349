<template>
    <div v-if="message.messageType === 'video' && message.fileLink">
        <div v-if="message.senderType == 'contact'" class="d-flex align-end justify-start mx-2 my-2">
            <div class="small-chat-img mr-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
            <div class="chat-recv-msg bg-white">
                <video v-if="isGif" class="compressed" autoplay muted loop>
                    <source :src="message.fileLink" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div v-else class="message-container" @click="openVideoDialog">
                    <div class="video-thumbnail">
                        <video ref="videoElement" class="compressed" muted>
                            <source :src="message.fileLink" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        <div class="play-button">
                            <!-- <v-icon icon="mdi-play-circle-outline"></v-icon> -->
                        </div>
                    </div>
                    <!-- Dialog for videos -->
                    <v-dialog v-model="videoDialog" width="auto">
                        <v-card>
                            <div class="video-container">
                                <video ref="videoPlayer" controls autoplay muted>
                                    <source :src="message.fileLink" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
    props: ["message", "iconColor", "status", "formattedTime"],
    computed: {
        ...mapGetters(["ENDPOINT", "teams", "selectedTeam"]),
    },
    data() {
        return {
            videoDialog: false,
            videoThumbnail: null,
            isGif: false,
            fileUrl: "",
        };
    },
    watch: {
        message: {
            deep: true, // Ensure nested changes are observed
            handler() {
                this.detectGif();
            },
        },
    },
    mounted() {
        console.log("video message: ", this.message);
        this.detectGif();
        // this.getImage()
    },
    methods: {
        // async getImage() {
        //     try {
        //         const url = this.message.fileLink;
        //         const fileName = url.split('/').pop();
        //         if (fileName) {
        //             console.log("File name extracted: ", fileName);
        //             const encodedFileName = encodeURIComponent(fileName);
        //             const fileApiUrl = `${this.ENDPOINT}/whatsapps/get-file-stream?filename=${encodedFileName}`;

        //             console.log("Fetching file from URL: ", fileApiUrl);

        //             // Fetch the file stream
        //             const response = await this.$http.get(fileApiUrl, { responseType: 'blob' }); // Ensure responseType is set to 'blob'
        //             console.log("Fetched video blob: ", response.body);

        //             // Create a Blob URL
        //             const blob = new Blob([response.body], { type: 'video/mp4' });
        //             this.fileUrl = URL.createObjectURL(blob);

        //             console.log("Blob URL created: ", this.fileUrl);
        //         } else {
        //             console.error("Could not extract file name from URL: ", url);
        //         }
        //     } catch (error) {
        //         console.error("Error fetching video: ", error);
        //     }
        // },
        async detectGif() {
            if (this.message.attachmentId) {
                var res = await this.$http.get(
                    this.$store.getters.ENDPOINT +
                    "/attachments/" + this.message.attachmentId);
                console.log("attachment resp: ", res.body)
                if (res.body.duration && res.body.duration < 6) {
                    this.isGif = true;
                }
            }

            console.log("isGif: ", this.isGif)
        },
        openVideoDialog() {
            this.videoDialog = true;
            this.$nextTick(() => { // Ensures the DOM updates are finished
                if (this.$refs.videoPlayer) {
                    this.$refs.videoPlayer.play();
                } else {
                    console.error('Video player not available');
                }
            });
        },
    }
};
</script>


<style scoped>
.video-container video {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.compressed {
    width: 100%;
    max-width: 10rem;
    object-fit: cover;
    height: auto;
    z-index: 0;
}

.bg-white {
    background-color: white;
}

.bg-blue {
    background-color: #dcf8c6;
    color: black;
}

.chat-recv-msg {

    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 8px;
    /* margin-left: 0.5rem; */
    max-width: 60%;
}

.image-container {
    overflow: hidden;
    cursor: grab;
    width: 100%;
    height: auto;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Adjust based on your dialog size */
}

.message-container {
    width: 100%;

}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    /* Adjust size as needed */
    height: 48px;
    /* Adjust size as needed */
    background: url('../../assets/Vector.svg') no-repeat center center;
    /* Use a play icon image */
    background-size: cover;
    cursor: pointer;
    z-index: 2;
    color: black;
    transition: background-image 0.3s ease;
}

.play-button:hover {
    background-image: url('../../assets/vector1.svg');
}


.small-chat-img {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* margin-left: 0.2rem; */
}

.small-chat-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.text-blue {
    color: #1877F2;
    font-size: 0.7rem;
}

.text-grey {
    color: #808080;
    font-size: 0.7rem;
}

.text-red {
    color: #ff3c3c;
    font-size: 0.7rem;
}

.ta-r {
    text-align: right;

}

.time {
    font-size: 0.6rem;
}

.video-thumbnail {
    position: relative;
    width: 100%;
    height: auto;
    /* padding-top: 56.25%; */
    /* 16:9 Aspect Ratio */
    background-size: cover;
    background-position: center;
}
</style>